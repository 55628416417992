import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import useScroll from "../../hooks/useScroll";

const SocialButton = ({ onClick, imgSrc, label }) => (
  <div
    className="flex gap-6 items-center px-4 py-2.5 mt-1.5 w-full border border-white border-solid cursor-pointer"
    onClick={onClick}
  >
    <img loading="lazy" src={imgSrc} className="object-contain shrink-0 w-6 aspect-square" />
    <div className="self-stretch my-auto">{label}</div>
  </div>
);

const InputField = ({ label, placeholder, type = "text" }) => (
  <div className="flex flex-col w-full">
    <label className="text-xs leading-none text-white mb-1">{label}</label>
    <input
      spellCheck="false"
      type={type}
      placeholder={placeholder}
      className="w-full py-1 text-sm leading-none text-white bg-transparent border-b border-white text-opacity-80 focus:outline-none placeholder-white placeholder-opacity-50"
    />
  </div>
);



const ModalLogin = ({ toggleLogin }) => {
  const [type, setType] = useState("loginSocial");

  return (
    <div className="absolute inset-0 z-[9999] flex justify-end" id="modalLogin">
      <div className="flex flex-col font-medium shadow-md bg-[#7bafcf] w-full md:w-[450px] md:rounded-r-2xl rounded-2xl md:rounded-l-none">
        <div className="flex overflow-hidden flex-col flex-1 px-10 py-9 w-full text-xs text-cyan-950">
          <div className="flex gap-5 items-start w-full text-lg">
            <div className="flex-1 shrink gap-2.5 self-stretch min-w-[240px]">
              Acesse a <span className="font-bold">magikk</span>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/fcdfeee7578770a76119269f14e22b2ded685ee21950544e4ab7bfa4cbbc093c?apiKey=103790acd32d40c0b511bf41726fd638&"
              className="object-contain shrink-0 w-6 aspect-square cursor-pointer"
              onClick={toggleLogin}
            />
          </div>

          {type !== "loginSocial" && (
            <div className="flex gap-5 items-center w-full text-xs font-semibold leading-none min-h-[25px] text-cyan-950 my-3 cursor-pointer"
              onClick={() => setType("loginSocial")} >
              <div className="flex flex-1 shrink gap-2.5 items-center self-stretch my-auto w-full basis-0 min-w-[240px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/e5ddea82be82f7b85096ea85234e661a3b573fae75f581a62150bc4990b64dd6?apiKey=103790acd32d40c0b511bf41726fd638&"
                  className="object-contain shrink-0 self-stretch my-auto aspect-square w-[17px]"

                />
                <div className="flex-1 shrink self-stretch my-auto basis-0 cursor-pointer">voltar</div>
              </div>
            </div>
          )}

          {type === "loginSocial" && (
            <div className="flex flex-col mt-5 w-full">
              <SocialButton
                imgSrc="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/fcf7a813ddef97a931924bf9ca5941eb9f96057f8b8921c3d74387216f6d732e?apiKey=103790acd32d40c0b511bf41726fd638&"
                label="Entrar com Google"
              />
              <SocialButton
                imgSrc="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/d27c8e8a110d444cd73e89c0272b3732e00f91651abf714736295075061d1b0a?apiKey=103790acd32d40c0b511bf41726fd638&"
                label="Entrar com Facebook"
              />
              <SocialButton
                imgSrc="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/517b589d621c0d4c506e75d8c6937bd9b6589cbc08b3ab6f5119aa598753131e?apiKey=103790acd32d40c0b511bf41726fd638&"
                label="Entrar com Apple"
              />
              <div
                className="flex gap-5 items-center mt-5 w-full font-semibold leading-none text-white min-h-[25px]"
                onClick={() => setType("loginEmail")}
              >
                <div className="flex-1 shrink self-stretch my-auto basis-0 cursor-pointer">Entrar com email e senha</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/d8f63305bfc1a6fc7179dc46f79f8105d07e2b87fee84a333a44eb4c5e38c3ae?apiKey=103790acd32d40c0b511bf41726fd638&"
                  className="object-contain shrink-0 self-stretch my-auto aspect-square w-[17px]"
                />
              </div>
            </div>
          )}

          {type === "loginEmail" && (
            <div className="flex flex-col max-w-[362px]">
              <InputField label="Email" placeholder="joao@gmail.com" />
              <InputField label="Senha" placeholder="••••••••" type="password" />
              <div
                className="flex items-center justify-center mt-5 px-8 py-4 rounded-[100px] bg-[#8dd0e4] text-white hover:bg-[#6ca0b8] cursor-pointer"
              >
                Acessar
              </div>
              <div
                className="flex justify-end mt-2 text-xs text-cyan-950 cursor-pointer"
                onClick={() => setType("lostPass")}
              >
                po**a, esqueci minha senha
              </div>
            </div>
          )}

          {type === "lostPass" && (
            <div className="flex flex-col max-w-[362px]">
              <InputField label="Email" placeholder="joao@gmail.com" />
              <div
                className="flex items-center justify-center mt-5 px-8 py-4 rounded-[100px] bg-cyan-950 text-white"
                onClick={() => alert("Código enviado!")}
              >
                Enviar código
              </div>
            </div>
          )}

          {type === "createAccount" && (
            <div className="grid grid-cols-2 gap-4 max-w-[362px]">
              <div className="col-span-2">
                <InputField label="Email" placeholder="seuemail@exemplo.com" />
              </div>

              <InputField label="Nome" placeholder="Seu Nome" />
              <InputField label="Sobrenome" placeholder="Seu Sobrenome" />

              <div className="col-span-2">
                <InputField label="Telefone" placeholder="(99) 99999-9999" />
              </div>

              <div className="col-span-2">
                <InputField label="Senha" placeholder="••••••••" />
              </div>
            </div>


          )}
        </div>

        {type === "createAccount" && (
          <div
            className="flex justify-center items-center py-6 text-base text-white bg-cyan-950 cursor-pointer rounded-b-2xl md:rounded-br-2xl md:rounded-l-none"
            onClick={() => setType("createAccount")}
          >
            Criar conta
          </div>
        )}


        {type !== "createAccount" && (
          <div
            className="flex justify-center items-center py-6 text-base text-white bg-cyan-950 cursor-pointer rounded-b-2xl md:rounded-br-2xl md:rounded-l-none"
            onClick={() => setType("createAccount")}
          >
            Não tem uma conta? Cadastre-se
          </div>
        )}
      </div>
    </div>
  );
};

const NavItem = ({ label, onClick }) => (
  <div
    className="gap-2.5 self-stretch px-3 py-1.5 my-auto border border-blue-400 border-solid rounded-[100px] cursor-pointer"
    onClick={onClick}
  >
    {label}
  </div>
);

const MainButton = ({ label, className, onClick }) => (
  <div
    className={`gap-2.5 self-stretch px-8 py-4 my-auto text-center rounded-[100px] cursor-pointer ${className}`}
    onClick={onClick}
  >
    {label}
  </div>
);


export default function Header() {
  const [showLogin, setShowLogin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const toggleLogin = () => setShowLogin(!showLogin);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = useScroll();

  return (
    <header className="flex flex-col items-center px-6 md:px-20 pt-8 pb-16 bg-[#004050] rounded-b-[50px]">

      <div className="flex flex-col w-full max-w-[1146px]">

        <nav className="flex justify-between items-center w-full text-white">

          <div className="w-[150px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/55741ec3bce57cb9b04cec76089695d0b380ccfed096d277e317117ba40ab374?apiKey=103790acd32d40c0b511bf41726fd638&"
              className="object-contain w-full cursor-pointer"
              alt="Logo"
              onClick={() => history.push('/')}
            />
          </div>

          <div className="hidden md:flex gap-10 items-center">
            <div className="flex gap-2 items-center text-base">
              <button onClick={() => history.push('/market?category=causes')}>doe</button>
              <div className="w-px bg-white h-[18px]" />
              <button onClick={() => history.push('/market?category=products')}>recompensas</button>
              <div className="w-px bg-white h-[18px]" />
              {/* anchor #platform */}
              <a href="#platform" className="cursor-pointer" onClick={() => scrollToSection("platform")}>karma</a>
            </div>
            <NavItem label="login" onClick={toggleLogin} />
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white text-2xl focus:outline-none"
            >
              ☰
            </button>
          </div>
        </nav>

        {isMenuOpen && (
          <div className="flex flex-col gap-4 mt-4 text-white md:hidden">
            <span>doe</span>
            <span>recompensas</span>
            <span>karma</span>
            <NavItem label="login" onClick={toggleLogin} />
          </div>
        )}

        <div className="flex flex-col mt-16 bg-white bg-opacity-30 rounded-3xl">
          <div className="relative min-h-[300px] sm:min-h-[400px] md:min-h-[429px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/d7d1dc19d6efca2a2557781822fd5d3270ed8cd2c26a06d7f35e84fa058ecde0?apiKey=103790acd32d40c0b511bf41726fd638&"
              className="absolute inset-0 object-cover w-full h-full rounded-3xl"
              alt="Background"
            />
            {showLogin && <ModalLogin toggleLogin={toggleLogin} />}


            <div className="flex flex-col items-center justify-center px-6 py-16 sm:px-12 sm:py-20 md:px-20 md:py-28 bg-opacity-70 relative">
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-8 sm:leading-9 md:leading-10 text-center text-white">
                Participe de experiências <br />
                de impacto gamificadas e <br />
                acumule recompensas.
              </h1>
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-5 items-center mt-8">
                <MainButton
                  label="Quero fazer o bem"
                  className="bg-[#8CCFEE] text-white"
                  onClick={toggleLogin}
                />
                <MainButton
                  label="Sou uma empresa"
                  className="bg-white text-blue-400 border border-white shadow-[0px_4px_4px_rgba(0,0,0,0.1)]"
                  onClick={() => history.push('/company-form')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

