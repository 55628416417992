import React from "react";

export default function KarmaSection() {
  return (
    <section className="self-center mt-32 md:w-3/4 w-11/12 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
      <article className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full z-20">
        <div className="flex flex-col justify-center self-stretch p-10 my-auto w-full text-lg bg-[#e6f2f9] rounded-[40px] max-md:px-5 max-md:mt-10">
          <h2 className="font-bold text-cyan-950">
            Ganhe karma ao se voluntariar ou doar para projetos sociais.
          </h2>
          <p className="mt-2.5 text-stone-900">
            O karma é nossa moeda social, criada para promover uma economia
            sustentável e solidária. Acumule karmas e troque por produtos e
            experiências oferecidos por marcas parceiras.
          </p>
        </div>
      </article>
      <article className="flex flex-col ml-5 md:w-[63%] max-md:ml-0 max-md:w-full z-10 w-11/12">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/49822889f5e8c0882618bdeb5852c1e99c4ef7d82d21fd2a72822eff919548dd?apiKey=103790acd32d40c0b511bf41726fd638&"
        alt="Karma rewards illustration"
        className="object-contain grow w-full aspect-[1.35] max-md:mt-10 max-md:max-w-full drop-shadow-[0px_12px_12px_rgba(0,0,0,0.4)]"
      />
    </article>
    </div>
    </section>
  );
}
