import React from "react";
import { connect } from "react-redux";
import facebook from "../../../assets/facebook.png";
import google from "../../../assets/google.png";
import apple from "../../../assets/apple.png";
import { useTranslation } from "react-i18next";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from 'react-apple-login';
import { setToastrTxt } from "../../../redux/Cart/cart.actions";
import {
  emailSignInStart,
  startFacebookLogin,
  startGoogleLogin,
  setFormError,
  startAppleLogin,
} from "../../../redux/Auth/auth.actions";


export const LoginForm = ({
  dispatchEmailLogin,
  dispatchFacebookLogin,
  dispatchGoogleLogin,
  dispatchAppleLogin,
  formError,
  clearFormError,
  dispatchSetToastrTxt,
}) => {
  const { t, i18n } = useTranslation();
  const [userCredentials, setUserCredentials] = React.useState({});

  React.useEffect(() => {
    clearFormError();

    // if query parameters have token, dispatch social login
    if (window.location.search.includes("token")) {

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const social_name = urlParams.get("social_name");
      const social_id = urlParams.get("social_id");
      const email = urlParams.get("email");
      const first_name = urlParams.get("first_name");
      const last_name = urlParams.get("last_name");

      if (token && social_name && social_id && email) {
        const socialUser = {
          email,
          first_name,
          last_name,
          token,
          "social-id": social_id,
          social_name,
        };
        dispatchAppleLogin(socialUser);
      }


    }
  }, []);

  const handleSubmit = () => {
    if (
      !userCredentials?.email?.length ||
      !userCredentials?.password?.length
    ) {
      dispatchSetToastrTxt({
        title: t("toastr.login"),
        txt: "Please enter your email and password",
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    } else {
      dispatchEmailLogin(userCredentials);
    }
  };

  const responseFacebook = (response) => {
    // console.log(response);

    if (response.accessToken) {
      let fullName = response.name;
      let first_name, last_name;

      const lastIndexOfSpace = fullName.lastIndexOf(" ");

      if (lastIndexOfSpace < 1) {
        first_name = fullName;
        last_name = "/";
      } else {
        first_name = fullName.substr(0, lastIndexOfSpace);
        last_name = fullName.substr(lastIndexOfSpace);
      }

      const socialUser = {
        email: response.email,
        first_name,
        last_name,
        token: response.accessToken,
        "social-id": response.userID,
        social_name: "facebook",
      };

      dispatchFacebookLogin(socialUser);
    } else {
      dispatchSetToastrTxt({
        title: t("toastr.login"),
        txt: "Facebook Login Failed",
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
      console.log("Facebook login failed");
    }
  };

  const responseGoogle = (response) => {
    // console.log(response);

    if (response?.profileObj?.email) {
      const socialUser = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
        token: response.tokenId,
        "social-id": response.googleId,
        social_name: "gmail",
      };

      dispatchGoogleLogin(socialUser);
    } else {
      dispatchSetToastrTxt({
        title: t("toastr.login"),
        txt: "Google Login Failed",
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
      console.log("Google login failed");
    }
  };

  const responseApple = (response) => {
    console.log(response);
    // Envie o response.authorization.code para o backend
    fetch('https://api.magikkarma.com/api/login/apple-front', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: response.authorization.code,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Faça algo com a resposta do backend, como salvar o token de acesso
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  };

  return (
    <div className="bg-theme h-75 font-LatoRegular items-center flex flex-col md:w-1/2 md:items-start">
      <h3 className="font-VolksDemiBold text-4xl mb-8">
        {t("login.log_in_account")}
      </h3>
      <h6 className="text-sm">{t("login.with_social")}</h6>

      <div className="flex flex-col mb-8 gap-4 md:w-5/12 sm:w-7/12 text-white w-12/12">
        <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            fields="name,email,picture"
            callback={responseFacebook}
            cssClass="social-login-btn"
            icon={<img src={facebook} alt="facebook" className="w-5 h-5" />}
            textButton=""
          />
          <span className="text-sm">{t("login.access_using_facebook")}</span>
        </div>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText=""
          onSuccess={responseGoogle}
          // onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          className="cursor-pointer"
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className=""
            >
              <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
                <div className="social-login-btn">
                  <img src={google} alt="google" className="w-5 h-5" />
                </div>
                <span className="text-sm">{t("login.access_using_google")}</span>
              </div>
            </div>
          )}
        />

        <AppleLogin
          clientId='com.magikk.webservice'
          callback={responseApple}
          scope="email name"
          responseMode="form_post"
          redirectURI="https://api.magikkarma.com/api/login/apple"
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className=""
            >
              <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
                <div className="social-login-btn">
                  <img src={apple} alt="apple" className="w-5 h-5" />
                </div>
                <span className="text-sm">{t("login.access_using_apple")}</span>
              </div>
            </div>
          )}
        />

      </div>

      {/* Campos de Login */}
      <div className="text-sm mb-4">{t("login.email_address")}</div>
      <input
        autoCorrect="off"
        onChange={(e) => {
          clearFormError();
          setUserCredentials({
            ...userCredentials,
            email: e.target.value,
          });
        }}
        type="text"
        className="input-classic mb-4 pl-4"
        autoComplete="off"
        value={userCredentials?.email}
        onFocus={(event) => {
          if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
          }
        }}
      />

      <div className="text-sm mb-4">{t("login.password")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            password: e.target.value,
          });
        }}
        type="password"
        className="input-classic mb-4 pl-4"
      />

      <div className="mb-8">
        <input type="checkbox" className="" />
        <span className="text-sm ml-3">{t("login.remember_password")}</span>
      </div>

      <div className="mb-4 text-red-500 text-lg font-bold">
        {JSON.stringify(formError)}
      </div>

      <div onClick={handleSubmit} className="btn-1 btn-effects mb-4">
        {t("login.sign_in")}
      </div>
      <a href={"/forget-password"} className="btn-effects text-hover">
        {t("login.forget_your_password")}
      </a>
    </div>
  );

};

const mapStateToProps = ({ auth }) => ({
  formError: auth.formError,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmailLogin: (userCredentials) =>
    dispatch(emailSignInStart(userCredentials)),
  dispatchFacebookLogin: (f) => dispatch(startFacebookLogin(f)),
  dispatchAppleLogin: (a) => dispatch(startAppleLogin(a)),
  dispatchGoogleLogin: (d) => dispatch(startGoogleLogin(d)),
  clearFormError: () => dispatch(setFormError()),
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  // dispatchAppleLogin: () => dispatch(startAppleLogin()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
