import React from "react";

export default function SealSection() {
  return (
    <section className="flex flex-col justify-center items-center mt-20 md:w-full w-11/12 max-md:mt-10 max-md:max-w-full self-center">
      <div className="gap-2.5  px-20 py-5 text-xl font-bold text-center border-2 border-blue-400 border-solid min-h-[69px] rounded-[1000px] text-cyan-950 max-md:px-5">
        Selo karma
      </div>
      <div className="flex flex-wrap gap-6 items-center text-lg text-stone-900 max-md:max-w-full">
        <div className="self-stretch px-8 py-7 my-auto bg-[#e6f2f9] min-w-[240px] rounded-[40px] w-[424px] max-md:px-5 max-md:max-w-full max-sm:pt-11 max-sm:mt-5">
          Ao ver o{" "}
          <span className="font-bold text-cyan-950">Selo karma</span>,
          você sabe que está em um local que valoriza o{" "}
          <span className="font-bold text-cyan-950">
            impacto positivo
          </span>{" "}
          e que faz parte de um movimento para espalhar o bem.
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/63fb7b7169fd4318da727388313c60eaf185f4d9bf9fa7ac8956cd342824519a?apiKey=103790acd32d40c0b511bf41726fd638&"
          alt="Karma Seal"
          className="object-contain self-stretch my-auto aspect-[0.98] min-w-[240px] rounded-[123px] w-[245px] max-sm:mx-auto"
        />
      </div>
    </section>
  );
}
