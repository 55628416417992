import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Toastr from "./Toastr";

function ToastNew({ toastObj }) {

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  }


  useEffect(() => {
    if (toastObj) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 4000);
    }
  }, [toastObj]);


  return (
    <div className="fixed shadow-md w-full max-w-sm top-16 left-1/2 transform -translate-x-1/2 sm:left-auto sm:right-4 z-50">
      {show && (
        <Toastr toastrTxt={toastObj} />
      )}
    </div>
  )
}

export default ToastNew;
