import React from "react";
import Logo from "./Logo";
import SocialLinks from "./SocialLinks";
import Address from "./Address";
import { useHistory } from 'react-router-dom';

export default function Footer() {

  const history = useHistory();
  return (
    <footer className="flex overflow-hidden flex-wrap gap-10 justify-center items-end py-20 pr-32 pl-32 mt-32 w-full bg-[#004050] rounded-[50px_50px_0px_0px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] ">
        <Logo hideRewardMessage={true} />
        <p className="mt-5 text-xs font-medium text-white">
          social tech que potencializa
          <br />
          estratégias de impacto.
        </p>
        <SocialLinks />
      </div>
      <nav className="flex flex-col flex-1 shrink text-xs font-semibold text-white basis-0 min-w-[240px]">
        <a
           onClick={() => history.push('/privacy-policy')}
          className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-400 cursor-pointer"
        >
          Política de Privacidade
        </a>
        <a
          onClick={() => history.push('/term-of-use')}
          className="mt-2.5 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-400 cursor-pointer"
        >
          Termos de uso
        </a>
      </nav>
      <Address />
    </footer>
  );
}
