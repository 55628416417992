import React from "react";

export default function PlatformSection() {
  return (
    <section id="platform" className="flex flex-wrap gap-10 justify-center items-center self-center mt-28 max-w-full text-lg font-medium text-center text-cyan-950 w-full max-md:mt-10">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/71d52683b3a36c083ee6a2706fac088746a1ff32c2a33792c0a7c93be3520171?apiKey=103790acd32d40c0b511bf41726fd638&"
        alt="Platform logo"
        className="object-contain shrink-0 self-stretch my-auto aspect-[3.83] w-[207px]"
      />
      <div className="flex flex-col items-end self-stretch my-auto min-w-[240px] md:w-[629px] max-md:max-w-full w-11/12">
        <div className="gap-2.5 self-stretch px-14 py-3.5 max-w-full border-2 border-blue-400 border-solid rounded-[1000px] w-[629px] max-md:px-5">
          Somos a plataforma que transforma suas <br />
          <span className="font-bold">boas ações</span> em{" "}
          <span className="font-bold">recompensas</span>.
        </div>
      </div>
    </section>
  );
}
