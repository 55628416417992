import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = props => {
  const { isAuthUser } = props;

  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    const documento = params.get("documento");
    const email = params.get("email");
    const codigo = params.get("codigo");

    let transferObject = {
      document: documento,
      email,
      code: codigo
    };

    if (documento && email && codigo) {
      fetch(`${process.env.REACT_APP_API_URI}/api/partners/checkin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transferObject)
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            console.log("success");
          }

          if(data.success === false && data.internal_code === 'checkin_already_done'){
            localStorage.setItem("checkin_already_done", true);
          }

          if (data.success === true) {
            localStorage.setItem("checkin_success", true);
          }
        })
        .catch(err => {
          console.log(err);
        }
        );
    }
  }


  if (!isAuthUser) return <Redirect to="/entrance" />;

  return <Route {...props} />;
};

const mapStateToProps = ({ auth }) => ({
  isAuthUser: auth.user
});

export default connect(mapStateToProps)(AuthRoute);