import React from "react";

export default function StatisticCard({
  percentage,
  image,
  label,
  height,
}) {
  return (
    <div className="flex gap-3.5 items-end">
      <div className="flex gap-6 items-end w-[60px]">
        <div className="flex flex-col w-[60px]">
          <div
            className={`px-4 pb-6 w-full bg-[#82BCE1] min-h-[${height}] rounded-[100px] flex items-end justify-center`}
          >
            {percentage}
          </div>
        </div>
      </div>
      <img
        loading="lazy"
        src={image}
        alt={`Statistic illustration for ${label}`}
        className="object-contain shrink-0 aspect-[0.97] w-[102px]"
      />
    </div>
  );
}
