import React from "react";

export default function Logo({hideRewardMessage = false}) {
  return (
    <div className="flex flex-col self-stretch my-auto text-xs w-[155px] max-sm:items-center max-sm:w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/55741ec3bce57cb9b04cec76089695d0b380ccfed096d277e317117ba40ab374?apiKey=103790acd32d40c0b511bf41726fd638&"
        alt="Company logo"
        className="object-contain aspect-[3.88] w-[155px]"
      />
      {!hideRewardMessage && <p className="mt-2.5">Recompensamos boas ações!</p>}
      
    </div>
  );
}
