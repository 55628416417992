import React from "react";
import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from 'react-icons/ai';
import {  IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5';
import colors from '../../constants/colors';


export default function SocialLinks() {

  const socialLinks = [
    {
      icon: <AiOutlineInstagram color={colors.bodyBg} size={24} />,
      link: "https://www.instagram.com/magikkarma",
    },
    {
      icon: <AiFillLinkedin color={colors.bodyBg} size={24} />,
      link: "https://www.linkedin.com/company/magikk-co/",
    },
    {
      icon: <AiFillFacebook color={colors.bodyBg} size={24} />,
      link: "https://www.facebook.com/magikkarma",
    },
    {
      icon: <IoLogoTiktok color={colors.bodyBg} size={24} />,
      link: "https://www.tiktok.com/@magikkarma",
    },
    {
      icon: <IoLogoYoutube color={colors.bodyBg} size={24} />,
      link: "https://www.youtube.com/@magikkarma",
    },
  ];



  return (
    <div className="flex flex-col items-start mt-5 w-full">
      <div className="flex overflow-hidden flex-row justify-center items-end ">
        {socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noreferrer"
            className="p-0.5"
          >
            {link.icon}
          </a>
        ))}   
      </div>
    </div>
  );
}
