import React from "react";
import Header from "../../components/LandingPage/Header";
import Footer from "../../components/LandingPage/Footer";
import HeroSection from "../../components/LandingPage/HeroSection"; 
import StatisticsSection from "../../components/LandingPage/StatisticsSection";
import PlatformSection from "../../components/LandingPage/PlatformSection";
import KarmaSection from "../../components/LandingPage/KarmaSection";
import CompanySection from "../../components/LandingPage/CompanySection";
import SealSection from "../../components/LandingPage/SealSection";
import "../../components/LandingPage/index.css";
import "../../components/NewLandingPage/tailwind.js";
import bg from "../../assets/bg.png";
export default function LandingPage() {
  
  return (
    <main className="flex overflow-hidden flex-col bg-no-repeat bg-right bg-fixed bg-white" style={{ backgroundImage: `url(${bg})`, zIndex:1 }}>
      <Header />
      <StatisticsSection />
      <PlatformSection />
      <KarmaSection />
      <CompanySection />
      <SealSection />
      <Footer />
    </main>
  );
}
