import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { startCancelSubscription } from "../../redux/Auth/auth.actions";


function Subscription( { subscriptions, dispatchCancelSubscription } ) {
  const { t, i18n } = useTranslation();

  const cancel = (subscriptionId) => {
    dispatchCancelSubscription(subscriptionId);
  }

  const getStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return t("profile.recurrent_donation.active");
    }
  }

  return (
    <div className="w-full mb-10 md:px-44 mt-6 sm:mt-12">
      <div className="mx-4 overflow-hidden rounded-t-ftxl">
        <div className="shadow-sm bg-white flex flex-row p-4">
          <h4 className="mx-8 h-10 text-xl bg-white text-black">
            {t("profile.my_recurrent_donations")}
          </h4>
        </div>
      </div>
      <div className="mx-4 overflow-hidden rounded-b-ftxl">
        <div className="shadow-sm bg-white p-4 rounded-b-ftxl">
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="text-left">
                  <th className="px-4 py-2">{t("profile.recurrent_donation.first_donation")}</th>
                  <th className="px-4 py-2">{t("profile.recurrent_donation.description")}</th>
                  <th className="px-4 py-2">{t("profile.recurrent_donation.value")}</th>
                  <th className="px-4 py-2">{t("profile.recurrent_donation.next_payment")}</th>
                  <th className="px-4 py-2">{t("profile.recurrent_donation.status")}</th>
                  <th className="px-4 py-2">#</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions && subscriptions.length > 0 && subscriptions.map((subscription, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="px-4 py-2">{moment(subscription.dateCreated).format("DD/MM/YYYY")}</td>
                    <td className="px-4 py-2">{subscription.description}</td>
                    <td className="px-4 py-2">R$ {subscription.value}</td>
                    <td className="px-4 py-2">{moment(subscription.nextDueDate).format("DD/MM/YYYY")}</td>
                    <td className="px-4 py-2">{getStatus(subscription.status)}</td>
                    <td className="px-4 py-2">
                      {subscription.status === "ACTIVE" && (
                        <button
                          onClick={() => cancel(subscription.id)}
                          className="btn-2 btn-effects w-20 py-2 px-4 text-sm"
                        >
                          {t("profile.cancel")}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
        </div>
      </div>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => ({
  dispatchCancelSubscription: (id) => dispatch(startCancelSubscription(id)),
});

export default connect(null, mapDispatchToProps)(Subscription);
