import React from "react";

export default function Address() {
  return (
    <address className="flex flex-1 shrink gap-2.5 items-start text-xs leading-4 text-white basis-0 min-w-[240px] not-italic">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/6c6a5fc8f58b2c6ada7d4b304ae31a3c566a53ec7501fdd115bf19c0bda2dc19?apiKey=103790acd32d40c0b511bf41726fd638&"
        alt="Location icon"
        className="object-contain shrink-0 aspect-[2.46] w-[86px]"
      />
      <div className="flex-1 shrink min-w-[240px]">
        Av. das Américas, 8585
        <br />
        Barra da Tijuca Rio de Janeiro - RJ
        <br />
        CEP: 22793-081
        <br />
        <span className="text-xs">
          Ref: Techset Academy - Fundo do estacionamento
        </span>
      </div>
    </address>
  );
}
