import React, { useState, useRef, useEffect } from "react";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai";
import { setProfileData, setGetAddressByPostalCode } from "../../redux/Profile/profile.actions";
import { handleUpdateProfile, handleDeleteProfile } from "../../redux/Profile/profile.helpers";
import { setToastrTxt } from "../../redux/Cart/cart.actions";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { checkCurrentUser } from "../../redux/Auth/auth.actions";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CustomModal from "../Shared/CustomModal";

function YourProfile({
  profileData,
  dispatchSetToastrTxt,
  dispathSetProfileData,
  dispatchCheckCurrentUser,
  dispatchGetAddressByPostalCode,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [userGender, setUserGender] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userDistrict, setUserDistrict] = useState('');
  const [userStateAbbr, setUserStateAbbr] = useState('');
  const firstName = useRef();
  const lastName = useRef();
  const phone = useRef();
  const birthdate = useRef();
  const countryID = useRef();
  const email = useRef();
  const cpf = useRef();
  const gender = useRef();
  const postalCode = useRef();
  const address = useRef();
  const number = useRef();
  const city = useRef();
  const state_abbr = useRef();
  const district = useRef();
  const addressByPostalCode = useSelector((state) => {
    return state.profile.addressByPostalCode;
  });

  const [inputError, setInputError] = useState([]);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModalVisibility = () => {
    setModalVisible(!isModalVisible);
  };

  const confirmDelete = () => {
    setModalVisible(false);
    handleDelete(); 
  };


  useEffect(() => {
    setUserGender(profileData?.gender);

    setUserAddress(profileData?.info?.address);
    setUserCity(profileData?.info?.city);
    setUserDistrict(profileData?.info?.district);
    setUserStateAbbr(profileData?.info?.state_abbr);
  }, []);

  useEffect(() => {
    if (addressByPostalCode) {
      setUserProfile({
        ...userProfile,
        state_abbr: addressByPostalCode.uf,
        district: addressByPostalCode.bairro,
        city: addressByPostalCode.localidade,
        address: addressByPostalCode.logradouro,
      });

      setUserAddress(addressByPostalCode.logradouro)
      setUserCity(addressByPostalCode.localidade)
      setUserDistrict(addressByPostalCode.bairro)
      setUserStateAbbr(addressByPostalCode.uf)
    }
  }, [addressByPostalCode]);

  const triggerToastr = ({ txt, err }) => {
    if (userProfile["email"] && !err) {
      const toastr_txt = t("your_pass.email_update");
      dispatchSetToastrTxt({
        title: t("toastr.profile"),
        txt: toastr_txt,
        clickEvent: () => {
          dispatchSetToastrTxt();
        },
      });
    } else {
      dispatchSetToastrTxt({
        title: t("toastr.profile"),
        txt: txt,
        err: err,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    }
  };

  const inputUpdate = (errList) => {
    let firstRequestErr =
      errList?.firstname || errList?.lastname || errList?.email;
    if (errList?.firstname) {
      firstName.current.value = profileData?.firstname
        ? profileData.firstname
        : "";
    }
    if (errList?.lastname) {
      lastName.current.value = profileData?.lastname
        ? profileData.lastname
        : "";
    }
    if (errList?.email) {
      email.current.value = profileData?.email
        ? profileData.email
        : "";
    }

    if (errList?.gender) {
      gender.current.value = profileData?.gender
        ? profileData.gender
        : "";
    }
    if (errList?.phone || firstRequestErr) {
      phone.current.value = profileData?.info?.phone
        ? profileData.info.phone
        : "";
    }
    if (errList?.birthdate || firstRequestErr) {
      birthdate.current.value = profileData?.birthdate
        ? profileData.birthdate
        : "";
    }
    if (errList?.document || firstRequestErr) {
      cpf.current.value = profileData?.info?.document
        ? profileData.info.document
        : "";
    }
    if (errList?.country_id || firstRequestErr) {
      countryID.current.value = profileData?.info?.country_id
        ? profileData.info.country_id
        : "";
    }
  };

  const handleSubmit = async () => {

    let inError = false;
    let errors = [];


    if (firstName?.current?.value?.length < 1) {
      errors.push("firstname");
      inError = true;
    }

    if (lastName?.current?.value?.length < 1) {
      errors.push("lastname");
      inError = true;
    }

    if (email?.current?.value?.length < 1) {
      errors.push("email");
      inError = true;
    }

    if (phone?.current?.value?.length < 1) {
      errors.push("phone");
      inError = true;
    }

    if (inError) {
      setInputError(errors);
      dispatchSetToastrTxt({
        title: t("toastr.profile"),
        txt: t("profile.update_error"),
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
      return;
    }

    let updateLength = Object.keys(userProfile).length;

    if (updateLength) {








      setInputError([]);
      handleUpdateProfile(userProfile)
        .then((data) => {
          dispathSetProfileData(data);
          triggerToastr({ txt: t("profile.update_success") });
          dispatchCheckCurrentUser();
        })
        .catch((err) => {
          console.log("Err", err);
          let errors = err?.errors ? err.errors : err.data;
          let errMessage =
            updateLength < 2 && err?.message
              ? err.message
              : t("profile.update_error");
          if (errors) {
            inputUpdate(errors);

            for (let key in errors) {
              triggerToastr({
                txt: errors[key][0],
                err: true,
              });
              break;
            }
          }
          dispatchCheckCurrentUser();
        });

    }
  };

  const handleDelete = () => {
    handleDeleteProfile()
      .then((data) => {
        triggerToastr({ txt: t("profile.delete_success") });
        dispatchCheckCurrentUser();
      })
      .catch((err) => {
        console.log("Err", err);
        triggerToastr({
          txt: t("profile.delete_error"),
          err: true,
        });
        dispatchCheckCurrentUser();
      });
  };

  return (
    <>
    <div className="bg-white rounded-ftxl mb-8">
      <div
        className="flex flex-row justify-between align-center py-8 px-16 rounded-ftxl btn-effects"
        onClick={() => setShow(!show)}
      >
        <h3 className="font-LatoRegular text-xl font-bold">
          {t("your_profile.your_profile")}
        </h3>
        {show ? (
          <AiOutlineDown size={21} />
        ) : (
          <AiOutlineRight size={21} />
        )}
      </div>

      <div
        className={`transform transition-all duration-500 ${!show ? "max-h-0" : "max-h-100"
          } overflow-hidden h-auto font-LatoRegular flex justify-between flex-col`}
      >
        <div className="flex flex-row justify-start px-12 py-4 flex-wrap">

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.first_name")}*</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  firstname: e.target.value?.trim(),
                });
              }}
              ref={firstName}
              className={`input-classic w-full pl-4 ${inputError.includes("firstname") ? "border-red-500" : ""}`}
              defaultValue={
                profileData?.firstname ? profileData?.firstname : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.last_name")}*</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  lastname: e.target.value?.trim(),
                });
              }}
              ref={lastName}
              className={`input-classic w-full pl-4 ${inputError.includes("lastname") ? "border-red-500" : ""}`}
              defaultValue={
                profileData?.lastname ? profileData?.lastname : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.your_email")}*</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  email: e.target.value?.trim(),
                });
              }}
              ref={email}
              defaultValue={
                profileData?.email ? profileData?.email : ""
              }
              className={`input-classic w-full pl-4 ${inputError.includes("email") ? "border-red-500" : ""}`}
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.phone")}*</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  phone: e.target.value?.trim(),
                });
              }}
              ref={phone}
              className={`input-classic w-full pl-4 ${inputError.includes("phone") ? "border-red-500" : ""}`}
              defaultValue={
                profileData?.info?.phone
                  ? profileData?.info?.phone
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.document")}*</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  document: e.target.value?.trim(),
                });
              }}
              ref={cpf}
              defaultValue={
                profileData?.info?.document
                  ? profileData?.info?.document
                  : ""
              }
              className={`input-classic w-full pl-4 ${inputError.includes("document") ? "border-red-500" : ""}`}
            />
          </div>

          {/* <div className="w-6/12 p-2">
            <p className="">{t("your_pay_meth.country")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  country_id: e.target.value?.trim(),
                });
              }}
              ref={countryID}
              defaultValue={
                profileData?.info?.country_id
                  ? profileData?.info?.country_id
                  : ""
              }
              className={`input-classic w-full pl-4`}
            />
          </div> */}

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.birthdate")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  birthdate: e.target.value?.trim(),
                });
              }}
              ref={birthdate}
              type="date"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.birthdate
                  ? profileData?.birthdate
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.gender_text")}</p>
            <select
              className={`input-classic w-full pl-4`}
              onChange={(e) => {
                setUserGender(e.target.value?.trim());
                setUserProfile({
                  ...userProfile,
                  gender: e.target.value?.trim(),
                });
              }}
              ref={gender}
              value={userGender}
            >
              <option value="">{t("your_profile.gender.select")}</option>
              <option value="feminino">{t("your_profile.gender.female")}</option>
              <option value="masculino">{t("your_profile.gender.male")}</option>
              <option value="nao_binarie">{t("your_profile.gender.nonbinary_gender_neutral")}</option>
              <option value="outro">{t("your_profile.gender.other")}</option>
              <option value="prefiro_nao_dizer">{t("your_profile.gender.prefer_not_to_say")}</option>
            </select>
          </div>

          {/* address information */}

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.pk")}</p>
            <input
              onChange={(e) => {
                const postalCode = e.target.value?.trim();

                setUserProfile({
                  ...userProfile,
                  postal_code: postalCode,
                });

                if (postalCode.toString().replace(/[^0-9]/g, "").length === 8) {
                  dispatchGetAddressByPostalCode(postalCode);
                }
              }}
              ref={postalCode}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.postal_code
                  ? profileData?.info?.postal_code
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.address")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  address: e.target.value?.trim(),
                });
              }}
              ref={address}
              value={userAddress}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.address
                  ? profileData?.info?.address
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.no")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  number: e.target.value?.trim(),
                });
              }}
              ref={number}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.number
                  ? profileData?.info?.number
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.district")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  district: e.target.value?.trim(),
                });
              }}
              ref={district}
              value={userDistrict}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.district
                  ? profileData?.info?.district
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.city")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  city: e.target.value?.trim(),
                });
              }}
              ref={city}
              value={userCity}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.city
                  ? profileData?.info?.city
                  : ""
              }
            />
          </div>

          <div className="w-6/12 p-2">
            <p className="">{t("your_profile.state_abbr")}</p>
            <input
              onChange={(e) => {
                setUserProfile({
                  ...userProfile,
                  state_abbr: e.target.value?.trim(),
                });
              }}
              ref={state_abbr}
              value={userStateAbbr}
              type="text"
              className={`input-classic w-full pl-4`}
              defaultValue={
                profileData?.info?.state_abbr
                  ? profileData?.info?.state_abbr
                  : ""
              }
            />
          </div>

          <div className="w-full p-2">
  <div
    className="btn-1 btn-effects ml-auto py-6 w-full md:w-48 whitespace-nowrap"
    onClick={handleSubmit}
  >
    {t("your_pass.update_changes")}
  </div>

  <div
    className="btn-2 btn-effects ml-auto py-6 w-full md:w-48 whitespace-nowrap mt-4"
    onClick={toggleModalVisibility}
  >
    {t("profile.delete_profile")}
  </div>
</div>
        </div>
      </div>
    </div>
      <CustomModal
        isVisible={isModalVisible}
        message={t("profile.confirm_delete_message")}
        onConfirm={confirmDelete}
        onCancel={toggleModalVisibility}
        template="danger" 
        confirmText={t("profile.yes_delete")}
        cancelText={t("profile.cancel")}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  dispathSetProfileData: (data) => dispatch(setProfileData(data)),
  dispatchCheckCurrentUser: () => dispatch(checkCurrentUser()),
  dispatchGetAddressByPostalCode: (data) => dispatch(setGetAddressByPostalCode(data)),
});

const mapState = ({ auth }) => ({
  //, profile }) => ({
  //profileData: profile.user || auth.user,
  profileData: auth.user,
});

export default connect(mapState, mapDispatchToProps)(YourProfile);
