import React from "react";
import StatisticCard from "./StatisticCard";
// import video from "../../assets/video.mp4";

export default function StatisticsSection() {
  const statistics = [
    {
      percentage: "57%",
      image:
        "https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/2f08c279b7c8036c3321583221ba31eb7d9cc3b08677d8d991725e7d7868d6ce?apiKey=103790acd32d40c0b511bf41726fd638&",
      label: "preço",
      height: "184px",
    },
    {
      percentage: "40%",
      image:
        "https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/a40890459f2dcaa2ff2330bb46401c24691bab6619ecc3e034e955277b1758a6?apiKey=103790acd32d40c0b511bf41726fd638&",
      label: "falta de apoio das empresas",
      height: "153px",
    },
  ];

  return (
    <section className="self-center mt-28 max-w-full md:w-2/3 w-full max-md:mt-10">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col max-md:ml-0 max-md:w-full">
        <video
                controls
                autoPlay={true}
                muted
                loop
                preload="lazy"
                className="object-contain md:w-[403px] w-10/12 md:p-0 rounded-b-[40px] rounded-t-[40px]  max-md:mt-10 z-10 self-center"
              >
                <source src="https://api.magikk.com/video_1.mp4" type="video/mp4" />
                Seu navegador não suporta vídeos HTML5.
              </video>
        </div>
        <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col justify-center px-8 py-7 max-w-full text-lg rounded-r-[40px] bg-[#e6f2f9] max-md:px-5 md:-mx-11 xs:-mx-4 w-11/12">
              <h2 className="font-bold text-cyan-950">
                As pessoas querem <br />
                fazer o bem
              </h2>
              <p className="mt-2.5 text-stone-900">
                mas ainda sentem que <br />
                existem barreiras:
              </p>
            </div>
            <div className="flex flex-col self-start mt-20 max-w-full font-bold  max-md:self-stretch max-md:mt-10 max-md:w-full">
              <div className="flex gap-10 items-end text-sm text-white whitespace-nowrap min-h-[184px] max-md:flex-wrap max-md:gap-5 max-md:justify-center w-11/12 self-center md:self-start md:w-full">
                {statistics.map((stat, index) => (
                  <StatisticCard key={index} {...stat} />
                ))}
              </div>
              <div className="flex flex-wrap gap-10 items-start mt-3 w-full text-base text-gray-700 max-md:gap-5 max-md:justify-center max-md:max-w-full max-md:text-center">
                {statistics.map((stat, index) => (
                  <div
                    key={index}
                    className="grow shrink text-center"
                  >
                    {stat.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
