import { useCallback } from "react";

const useScroll = () => {
  const scrollTo = useCallback((id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return scrollTo;
};

export default useScroll;
