import React, { useState } from "react";
import { connect } from "react-redux";
import google from "../../../assets/google.png";
import apple from "../../../assets/apple.png";
import facebook from "../../../assets/facebook.png";
import { useTranslation } from "react-i18next";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from 'react-apple-login';
import { useHistory } from "react-router-dom";

import {
  createAccount,
  setFormError,
  startFacebookLogin,
  startGoogleLogin,
  startAppleLogin,
  
} from "../../../redux/Auth/auth.actions";

const RegisterForm = ({
  startCreateAccount,
  navigation,
  formError,
  clearFormError,
  dispatchFacebookLogin,
  dispatchGoogleLogin,
  dispatchAppleLogin,
}) => {
  const history = useHistory();

  const [userCredentials, setUserCredentials] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptError, setAcceptError] = useState(false);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  let firstError =
    formError && Object.keys(formError) && Object.keys(formError)[0];
  let errorTxt = formError && formError[firstError];

  React.useEffect(() => {
    localStorage.removeItem("newuser");
    setLoading(false);
  }, [formError]);

  React.useEffect(() => {
    clearFormError();

    // if query parameters have token, dispatch social login
    if (window.location.search.includes("token")) {

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const social_name = urlParams.get("social_name");
      const social_id = urlParams.get("social_id");
      const email = urlParams.get("email");
      const first_name = urlParams.get("first_name");
      const last_name = urlParams.get("last_name");

      if (token && social_name && social_id && email) {
        const socialUser = {
          email,
          first_name,
          last_name,
          token,
          "social-id": social_id,
          social_name,
        };
        dispatchAppleLogin(socialUser);
      }


    }
  }, []);



  const handleSubmit = () => {
    localStorage.setItem("newuser", "true");
    setLoading(true);
    setTimeout(() => {
      startCreateAccount(userCredentials);
    }, 500);
  };

  const responseFacebook = (response) => {
    // console.log(response);

    if (response.accessToken) {
      let fullName = response.name;
      let first_name, last_name;

      const lastIndexOfSpace = fullName.lastIndexOf(" ");

      if (lastIndexOfSpace < 1) {
        first_name = fullName;
        last_name = "/";
      } else {
        first_name = fullName.substr(0, lastIndexOfSpace);
        last_name = fullName.substr(lastIndexOfSpace);
      }

      const socialUser = {
        email: response.email,
        first_name,
        last_name,
        token: response.accessToken,
        "social-id": response.userID,
        social_name: "facebook",
      };

      dispatchFacebookLogin(socialUser);
    } else {
      console.log("Facebook login failed");
    }
  };

  const responseGoogle = (response) => {
    // console.log(response);

    if (response?.profileObj?.email) {
      const socialUser = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
        token: response.tokenId,
        "social-id": response.googleId,
        social_name: "gmail",
      };

      dispatchGoogleLogin(socialUser);
    } else {
      console.log("Google login failed");
    }
  };

  const responseApple = (response) => {
    console.log(response);
    // Envie o response.authorization.code para o backend
    fetch('https://api.magikkarma.com/api/login/apple-front', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: response.authorization.code,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Faça algo com a resposta do backend, como salvar o token de acesso
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  };

  return (
    <div className="bg-theme h-75 font-LatoRegular items-center flex flex-col md:w-1/2 md:items-start">
      <h3 className="font-VolksDemiBold text-4xl mb-8">
        {t("register.create_account")}
      </h3>
      <h6 className="text-sm">{t("login.with_social")}</h6>
      <div className="flex flex-col mb-8 gap-4 md:w-5/12 sm:w-7/12 text-white w-12/12">
      <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            fields="name,email,picture"
            callback={responseFacebook}
            cssClass="social-login-btn"
            icon={<img src={facebook} alt="facebook" className="w-5 h-5" />}
            textButton=""
          />
          <span className="text-sm">{t("login.access_using_facebook")}</span>
        </div>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText=""
          onSuccess={responseGoogle}
          // onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          className="cursor-pointer"
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className=""
            >
              <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
                <div className="social-login-btn">
                  <img src={google} alt="google" className="w-5 h-5" />
                </div>
                <span className="text-sm">{t("login.access_using_google")}</span>
              </div>
            </div>
          )}
        />

        <AppleLogin
          clientId='com.magikk.webservice'
          callback={responseApple}
          scope="email name"
          responseMode="form_post"
          redirectURI="https://api.magikkarma.com/api/login/apple"
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className=""
            >
              <div className="flex items-center gap-3 bg-primary rounded-md p-2 cursor-pointer">
                <div className="social-login-btn">
                  <img src={apple} alt="apple" className="w-5 h-5" />
                </div>
                <span className="text-sm">{t("login.access_using_apple")}</span>
              </div>
            </div>
          )}
        />

      {/*<AppleLogin
        clientId='com.magikk.webservice'
        callback={responseApple} // Catch the response
        scope="email name"
        responseMode="query"
        redirectURI="https://api.magikkarma.com/api/login/apple"
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className="social-login-btn"
            >
              <img src={apple} alt="apple" className="p-3" />
            </div>
          )}
          /> */}

        {/* <div onClick={dispatchGoogleLogin} className="social-login-btn">
          <img src={google} />
        </div> */}
      </div>
      <div className="text-sm mb-4">{t("login.email_address")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            email: e.target.value,
          });
        }}
        type="text"
        className="input-classic mb-4 pl-5"
        placeholder={process.env.REACT_APP_PLACEHOLDER_EMAIL}
      />
      <div className="text-sm mb-4">{t("register.firstname")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            firstname: e.target.value,
          });
        }}
        type="text"
        className="input-classic mb-4 pl-5"
        placeholder={"esteban"}
      />
      <div className="text-sm mb-4">{t("register.lastname")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            lastname: e.target.value,
          });
        }}
        type="text"
        className="input-classic mb-4 pl-5"
        placeholder={"schiller"}
        autoComplete={"off"}
      />
      <div className="text-sm mb-4">{t("register.phone")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            phone: e.target.value,
          });
        }}
        type="text"
        className="input-classic mb-4 pl-5"
        placeholder={"(99) 9 9999-9999"}
        autoComplete={"off"}
      />
      <div className="text-sm mb-4">{t("login.password")}</div>
      <input
        onChange={(e) => {
          setUserCredentials({
            ...userCredentials,
            password: e.target.value,
            password_confirmation: e.target.value,
          });
        }}
        type="password"
        className="input-classic mb-4 pl-5"
        placeholder={process.env.REACT_APP_PLACEHOLDER_PASSWORD}
      />
      <div className="mb-4 text-red-500 text-lg font-bold">
        {acceptError && (<span>{t("register.accept_terms_error")}</span>)}
      </div>
      <div className="mb-6">
        <input
          onChange={(e) => {
            const val = e.target.checked;
            setAcceptTerms(val);

            if (val) {
              setAcceptError(false);
            }
          }}
          type="checkbox"
          className=""
        />
        <a href={"/term-of-use"} target="_blank"><span
          className={`text-sm ml-3 text-blue-500 ${acceptError && "text-red-500 font-bold"
            }`}
        >
          {t("register.accept_terms")}
        </span></a>
      </div>
      <div className="mb-4 text-red-500 text-lg font-bold">
        {errorTxt}
      </div>

      <div
        onClick={() => {

          if (loading) {
            return;
          }
          else if (acceptTerms) {
            handleSubmit();
          } else {
            setAcceptError(true);
          }
        }}
        className={`btn-1 btn-effects mb-4 ${loading && "btn-loading"}`}
      >
        {t("login.sign_up")}
      </div>
      <div
        onClick={() => history.push("/login")}
        className="btn-effects"
      >
        {t("register.already_have_an_account")}{" "}
        <span className="text-blue-500">{t("login.login")}</span>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startCreateAccount: (form) => dispatch(createAccount(form)),
  clearFormError: () => dispatch(setFormError()),
  dispatchFacebookLogin: (f) => dispatch(startFacebookLogin(f)),
  dispatchGoogleLogin: (d) => dispatch(startGoogleLogin(d)),
  dispatchAppleLogin: (a) => dispatch(startAppleLogin(a)),
});

const mapState = ({ auth }) => ({
  user: auth.user,
  formError: auth.formError,
});

export default connect(mapState, mapDispatchToProps)(RegisterForm);
