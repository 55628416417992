import React from "react";
import computerIcon from "../../assets/computerIcon.svg";
import { useHistory } from 'react-router-dom';

const HighlightBox = ({ title, description }) => (
  <div className="flex flex-col justify-center md:items-start items-center px-14 py-5 border-2 border-blue-400 border-solid min-h-[94px] rounded-[1000px] max-md:px-5 max-md:max-w-full">
    <div className="text-xl font-bold">{title}</div>
    <div className="text-lg">{description}</div>
  </div>
);

const ResponsiveImage = ({ src, alt, className }) => (
  <img
    loading="lazy"
    srcSet={src}
    alt={alt}
    className={`object-contain ${className}`}
  />
);

const IconGallery = ({ icons, side = "right" }) => (
  <div className={`absolute flex justify-center ${side === "right" ? "right-[-30px]" : "left-[-30px] mt-9"}`}>
    <div className="flex flex-col min-h-[143px] self-center">
      {icons.map((icon, index) => (
        <ResponsiveImage
          key={index}
          src={icon.src}
          alt={icon.alt}
          className={`aspect-square w-[65px] ${index > 0 ? "mt-3" : ""}`}
        />
      ))}
    </div>
  </div>
);

export default function CompanySection() {
  const history = useHistory();
  const icons = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/bf5399498781f3c8b3a715824a57df969669d7f16c401019c631fabd9ebc6049?apiKey=103790acd32d40c0b511bf41726fd638&width=100 100w",
      alt: "Icon 1",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/0f6078fa67bcb01142ed4b55fe3dc5baca885ef5adac77e49f0020ceacbb3bc8?apiKey=103790acd32d40c0b511bf41726fd638&width=100 100w",
      alt: "Icon 2",
    },
  ];

  const secondColumnIcons = [
    {
      src: computerIcon,
      alt: "Small icon 1",
    },
  ];



  return (
    <div className="flex overflow-hidden flex-col justify-center py-32 mt-24 w-11/12 md:w-full self-center bg-[#004050] rounded-[50px] max-md:py-24 max-md:mt-10 max-md:max-w-full">
      <div className="mb-0 w-full max-md:mb-2.5 max-md:max-w-full">
        <div className="flex gap-24 max-md:flex-col">

          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
              <div className="flex">
                <div className="flex flex-col grow shrink-0 mr-0 text-white basis-0 w-fit max-md:max-w-full">
                  <div className="flex flex-col items-start px-14 w-full text-center max-md:px-5 max-md:max-w-full">
                    <HighlightBox
                      title="Empresas"
                      description="Envolva seus clientes e colaboradores"
                    />
                  </div>
                  <div className="relative px-16 py-12 mt-14 w-11/12 md:w-full text-xl rounded-none bg-[#245A6D] max-md:px-5 max-md:mt-10 max-md:max-w-full rounded-r-[40px]">
                    <IconGallery icons={icons} />
                    Potencializamos suas{" "}
                    <span className="font-semibold">estratégias de ESG</span>,
                    tornando metas de impacto em métricas mensuráveis.
                    Desenvolvemos{" "}
                    <span className="font-semibold">
                      experiências de impacto
                    </span>{" "}
                    gamificadas para{" "}
                    <span className="font-semibold">
                      recompensar as boas ações
                    </span>{" "}
                    e oferecer benefícios exclusivos para clientes e
                    colaboradores que fizeram o bem.
                  </div>
                </div>
              </div>
              <ResponsiveImage
                src="https://cdn.builder.io/api/v1/image/assets/103790acd32d40c0b511bf41726fd638/a3bc64dcee6b9c96d6aece21cf8ad11ac4d1077c63a2b7b266ad6439c05a083b?apiKey=103790acd32d40c0b511bf41726fd638&width=400"
                alt="Main visual"
                className="self-center mt-24 w-[481px] max-md:mt-10 px-4"
              />
            </div>
          </div>

          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col pb-20 w-full max-md:mt-10 max-md:max-w-full">
              <video
                controls
                autoPlay={true}
                muted
                loop
                preload="lazy"
                className="object-contain md:w-[403px] w-11/12 rounded-b-[40px] rounded-t-[40px] max-md:mt-10 z-10 self-center md:self-start"
              >
                <source src="https://api.magikk.com/video.mp4" type="video/mp4" />
                Seu navegador não suporta vídeos HTML5.
              </video>
              <div className="relative flex flex-col justify-center px-16 py-7 mt-16 w-11/12 md:w-fit bg-[#245A6D] rounded-l-[40px] max-md:mt-10 max-md:max-w-full ml-auto">
                <IconGallery icons={secondColumnIcons} side="left" />
                <div className="text-xl text-white max-md:max-w-full">
                  Acompanhe suas{" "}
                  <span className="font-semibold">metas de impacto</span> e{" "}
                  <span className="font-semibold">KPIs de negócio</span> via nosso{" "}
                  <span className="font-semibold">dashboard interativo</span>.
                </div>
                <div
                onClick={() => history.push('/company-form')}
                  className={`inline-block px-8 py-4 mt-6 text-center rounded-[100px] cursor-pointer bg-white text-[#74B8DB] max-md:mx-auto w-full lg:w-8/12 xl:w-6/12 2xl:w-4/12`}
                >
                  Sou uma empresa
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
